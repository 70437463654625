import React from 'react';
import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import FAQItem from '../../components/FAQ-item'; // can be imported as src/omponents/FAQ-item

import './styles.scss';
import {Link} from 'gatsby';

const FAQ = () => {
  return (
    <Layout>
      <PageHero title="Hexomatic FAQ" subtitle="On this page you can get the answers to FAQs" titleSmall />
      <Section>
        <div className="col-12 d-block mb_20 m-auto" style={{maxWidth: '800px', width: '100%'}}>
          {/*<FAQItem
            title="When will I get access to Hexomatic?"
            text={
              <p>
                We plan to soft launch Hexomatic on the May 22nd and will send
                you access details on the day by email.
              </p>
            }
          />*/}
          {/* <FAQItem
            title="What is the refund period?"
            text={
              <>
                <p>
                  All our paid plans are covered by our 30-day refund policy so if Hexomatic is not a right fit for your
                  business we provide a full refund.
                </p>
              </>
            }
          /> */}
          <FAQItem
            title="How are automation credits counted?"
            text={
              <>
                <p>
                  Automation credits are used when we perform an action (for example fetch a page, extract emails,
                  create a screenshot, get WHOIS data, Extract social media links automatically, discover techstack,
                  etc.)
                </p>
                <p>
                  We also provide free internal actions once a page has been retrieved or an automation task has run,
                  for example to select fields to capture, perform date/text/number formatting, conditional logic etc.
                </p>
              </>
            }
          />
          <FAQItem
            title="Can I use Hexomatic to perform Social media automations?"
            text={
              <>
                <p>
                  Hexomatic can be used to scrape most websites, but is not designed to perform social media scraping
                  and interactions such as automated following, liking, connection requests, or other actions which can
                  get users banned from social media websites such as Instagram, Facebook or LinkedIn.
                </p>
                <p>We recently added Twitter data using the official API and YouTube video scraping.</p>
              </>
            }
          />
          <FAQItem
            title="Which websites can I scrape using Hexomatic?"
            text={
              <>
                <p>
                  Hexomatic features a built in website scraper that you can program to extract text, numbers or images
                  at scale with pagination support.
                </p>
                <p>
                  Some websites employ more advanced countermeasures which may block our requests, our scraping engine
                  comes with built-in rotating IP's that helps prevent this.
                </p>
                <p>
                  For websites that employ more advanced countermeasures we've released an optional residential proxy
                  add-on using our premium credit system.
                </p>
              </>
            }
          />
          <FAQItem
            title="What is the difference between Hexomatic and Hexowatch?"
            text={
              <>
                <p>
                  Hexowatch is a change monitoring platform that alerts you when visual, content, keyword, source code,
                  price, availability, backlink, API or sitemap changes are detected.
                </p>
                <p>
                  Hexomatic is a work automation platform that enables you to capture data, run automations and leverage
                  3rd party services to automate time consuming tasks at scale.
                </p>
              </>
            }
          />
          <FAQItem
            title="What is the difference between Hexomatic and app integration automation platforms?"
            text={
              <>
                <p>
                  Application integration platforms connect data from one application or service to another. For
                  example, these types of applications enable you to send data from your eCommerce shop when an order is
                  made to your email marketing platform and works like a data pipe.
                </p>
                <p>
                  Hexomatic on the other hand enables you to create custom website scraping recipes, perform custom
                  actions, leverage different pre-built automations, 3rd party services and manage human tasks using a
                  no-code, visual point and click workflow.
                </p>
                <p>
                  Best of all you can connect Hexomatic to Zapier, Pabbly Connect, Integrately and Syncspider inside
                  your workflows to send alerts or data from your automation to a wide range of apps and services.
                </p>
              </>
            }
          />
          <FAQItem
            title="What are premium automations?"
            text={
              <>
                <p>Hexomatic provides two types of actions and automations you can use in your workflows:</p>
                <ul>
                  <li>
                    Built-in automations and actions are created by the Hexomatic team and consume automation credits
                    from your monthly quota.
                  </li>
                  <li>
                    Premium automations provide access to external 3rd party services (for example providing machine
                    translation, proxy or AI services)
                  </li>
                </ul>
                <p>
                  Premium automations are available via premium credits, an optional add-on starting at just $9.99/m.
                  These credits are not included in any of our paid or LTD plans.
                </p>
              </>
            }
          />
          <FAQItem
            title="What is the difference between 10 and unlimited workflows between Silver and Gold plans?"
            text={
              <>
                <p>
                  Within Hexomatic you will be able to create different workflows that you can run manually or schedule.
                  We recommend the Gold plan if you would like to schedule more than 10 workflows at a time or run more
                  than 10 concurrent workflows at a time.
                </p>
              </>
            }
          />

          <FAQItem
            title="Can Hexomatic scrape data from password protected pages?"
            text={
              <p>
                Hexomatic can scrape data from password protected pages. When creating a recipe for a webpage you can
                use “Type” and “Click” actions to fill out forms and login to access password protected pages. You can
                then scrape pages as normal as long as it is not against the TOS of the target website.
              </p>
            }
          />

          <FAQItem
            title="Will Hexomatic enable me to scrape this … website?"
            text={
              <p>
                We can’t provide specifics as to which websites will be able to be scrapable, but our scraping engines
                provide IP rotation as standard, and for more complex situations where countermeasures are used you can
                use our optional residential proxy add-on which will use premium credits to help in these instances.
              </p>
            }
          />
          <FAQItem
            title="Can I copy the product descriptions on the site of my supplier and translate them?"
            text={
              <>
                <p>
                  Yes, the idea is to enable you to chain together different automations, so for example in this usecase
                  you could:
                </p>
                <ul>
                  <li>
                    Create a scraper for your supplier to keep a CSV or Google sheet updated with the latest products
                    and info
                  </li>
                  <li>
                    Then have an automation to translate product name/description into a new field for each row then you
                    can chain another automation to send you a Slack message when ready
                  </li>
                </ul>
              </>
            }
          />

          <FAQItem
            title="What are AI features used for?"
            text={
              <>
                <p>Hexomatic provides access to a wide range of AI powered automations including:</p>
                <p>
                  AI Text To Speech premium automation. It enables text to be converted into speech sounds imitative of
                  the human voice via Google Text-to-Speech.
                </p>
                <p>
                  AI Audio Transcription premium automation by using speech recognition capabilities automatically
                  converts audio files into text.
                </p>
                <p>AI Document OCR premium automation can recognize text from PDF documents by Google Vision AI.</p>
                <p>AI Image OCR premium automation detects and extracts texts from any image.</p>
                <p>
                  AI Sentiment analysis premium automation provides sentiment analysis for any text fragment or page
                  based on positive, negative or neutral sentiment at scale.{' '}
                </p>
                <p>
                  AI image labeling premium automation intelligently detects and extracts image labels via Google Vision
                  AI.
                </p>
                <p>AI image safety performs image safety checks via Google Vision AI to check for adult themes.</p>
                <p>AI logo detection premium automation detects product logos within an image via Google Vision AI. </p>
              </>
            }
          />

          <FAQItem
            title="How do automation credits work?"
            text={
              <>
                <p>
                  Each plan gets an automation credit quota that renews each month. Automation credits are used when we
                  perform an action (for example fetch a page, extract emails, create a screenshot, get WHOIS data,
                  Extract social media links automatically, discover techstack, etc.
                </p>

                <p>
                  We also provide free internal actions once a page has been retrieved or an automation task has run,
                  for example to select fields to capture, perform date/text/number formatting, conditional logic etc.
                </p>
                <p>To illustrate imagine you have a product supplier with 100 product pages.</p>
                <p>
                  If you created a scraping recipe to scrape these, each page request would consume 1 automation credit
                  (even if you capture the title, product name, description, price for each product). So this would
                  consume 100 credits.
                </p>
                <p>
                  If you wanted to automatically create a screenshot of each page, that would be a credit each time, so
                  100 more.
                </p>
                <p>
                  Then if you save this to a CSV or Google sheet it will consume 1 additional automation credit. Sending
                  a link to the sheet or a request via Zapier or Pabbly Connect would be a credit per call as well.
                </p>
              </>
            }
          />
          <FAQItem
            title="Do premium credits roll over?"
            text={
              <>
                <p>
                  Premium credits provide access to managed automations and a wide range of 3rd party services including
                  residential proxies, translation and AI services on a discounted and pay per use basis saving $$$ in
                  subscription fees.
                </p>
                <p>
                  Premium automations are continually monitored and updated by our team and some 3rd party services such
                  as residential proxies incur substantial subscription fees in order for Hexomatic to bring down the
                  cost of access to our users.
                </p>
                <p>
                  In order to provide these benefits we are unable to transfer the unused premium credits to the next
                  month. In case of monthly subscription you can subscribe, pause and restart it at any time.
                </p>
              </>
            }
          />
          <FAQItem
            title="Are payments for Premium credits refundable?"
            text={
              <>
                <p>Premium credits are not refundable.</p>
              </>
            }
          />
          <FAQItem
            title="What integrations Hexomatic provide?"
            text={
              <>
                <p>Hexomatic provides integrations with:</p>

                <ul>
                  <li>Google Sheets</li>
                  <li>Discord</li>
                  <li>Telegram</li>
                  <li>Slack</li>
                  <li>Microsoft Teams</li>
                  <li>Integromat</li>
                  <li>SQL databases</li>
                  <li>Zapier</li>
                  <li>Integrately</li>
                  <li>Pabbly Connect</li>
                  <li>KonnectzIT</li>
                  <li>WordPress</li>
                </ul>
              </>
            }
          />

          <FAQItem
            title="Do you have any use-cases to illustrate how Hexomatic works?"
            text={
              <>
                <p>
                  You can find some of our use-cases here:
                  <Link to="https://hexomatic.com/use-cases">https://hexomatic.com/use-cases</Link>
                </p>

                {/* <p>
                  The idea with Hexomatic is that you can build your own
                  workflow taking advantage of your own scraping recipes (or
                  community recipes in Q2), built in automations as well as
                  premium automations powered by 3rd party services (for ex:
                  machine translation / AI services etc), or crowdsourcing.
                </p>
                <p>
                  Together, this opens up a wide range of potential use-cases
                  that would otherwise require manual work, programming with
                  complex API's or hiring and managing assistants. There is
                  nothing quite like this and we are excited to be inviting our
                  existing customers on this new journey.
                </p>

                <p>
                  The goal of this pre-launch is to recruit a small group of
                  existing customers who are already onboard with our vision, to
                  understand their needs, polish the experience and reward them
                  with early access + our best pricing.
                </p>

                <p>
                  Then once we launch to the public later this year we will make
                  more marketing materials, examples, videos etc for new folks
                  unfamiliar with the Hexo brands.
                </p> */}
              </>
            }
          />

          <FAQItem
            title="Can I stack more than 10X?"
            text={<p>Yes, you can purchase multiple stacks (multiple times), automation credits cap is 1 mln.</p>}
          />
          <FAQItem
            title="What is a data input?"
            text={
              <>
                <p>
                  Hexomatic workflows consist of two core parts: A starting point and a series of automations or
                  scraping recipes that perform work tasks.
                </p>
                <p>The Data Input automation enables you to provide data as a starting point for your workflow.</p>
                <p>We currently support the following types of data inputs:</p>
                <p>
                  <ul>
                    <li>Upload CSV, TXT, JSON. PDF, Image or Audio files (for example containing URLS or documents)</li>
                    <li> External CSV. TXT. JSON files hosted remotely hosted externally</li>
                    <li>
                      Paste as list of inputs - Lets you provide a list of URLS or keywords and iterate through each in
                      your workflow
                    </li>
                    <li>Paste text - Lets you copy paste a piece of text ideal for single translations.</li>
                  </ul>
                </p>
                <p>
                  You can then add additional automations to perform different work tasks for each URL, Keyword or data
                  provided in the data input.
                </p>
              </>
            }
          />
        </div>
      </Section>
    </Layout>
  );
};

export default FAQ;
